<template>
  <land-section
    id="home_search"
    space="30"
  >
    <v-container
      style="max-width: 900px;"
    >
      <v-row
        justify="center"
      >
        <v-col
          cols="12"
        >
          <div class="co-flex-col">
            <div class="co-flex-row co-items-center co-w-full co-bg-white co-border co-border-major co-round-md co-overflow-hidden">
              <input
                v-model="btnFind.content"
                class="co-border-none co-border-transparent co-py2 co-px8"
                style="outline: none; flex: 1;"
                placeholder="输入关键字(用户/作品/活动)"
              >
              <v-btn
                tile
                x-large
                :color="btnFind.color"
                :width="btnFind.width"
                @click="onFind"
              >
                {{ btnFind.text }}
              </v-btn>
            </div>
            <div class="co-flex-row co-items-center co-my6">
              <span class="co-text-sm co-text-gray-500 mr-2">
                查询类型:
              </span>
              <span
                v-for="tag in selTags"
                :key="tag.value"
                class="co-text-sm py-1 px-4 mr-3 co-round-md"
                :class="[
                  tag.selected ? backColors[tag.color] : 'co-bg-gray-400',
                  tag.selected ? 'co-text-white' : 'co-text-gray-700'
                ]"
                style="cursor: pointer;"
                @click="onTag(tag)"
              >
                {{ tag.text }}
              </span>
            </div>

            <div
              v-if="selResult.content"
              class="co-flex-row co-items-center co-my6"
            >
              <span class="co-text-xs co-text-gray-600">查询到</span>
              <span class="co-text-xs co-text-gray-800 co-font-600 px-2">
                {{ `“${selResult.content}”` }}
              </span>
              <span class="co-text-xs co-text-gray-600">相关内容，共</span>
              <span class="co-text-xs co-text-gray-800 co-font-600 px-2">
                {{ `${selResult.count}` }}
              </span>
              <span class="co-text-xs co-text-gray-600">条</span>
            </div>
            <v-divider />
          </div>
        </v-col>
      </v-row>
      <v-row
        justify="center"
      >
        <v-col
          cols="12"
        >
          <div
            v-for="(item, index) in items"
            :key="`cnt_${index}`"
            class="d-flex flex-column my-6"
          >
            <span
              v-if="item.titles && item.titles.length > 1"
              class="co-text-md mb-2"
            >
              <span
                v-for="(tit, titIndex) in item.titles"
                :key="`tit_${titIndex}`"
                :class="[tit.isPound ? 'co-font-600 co-text-major' : '']"
                @click="toPound(tit)"
              >
                {{ tit.title }}
              </span>
              <span
                class="co-text-xs co-bg-blue-100 co-round co-py2 co-px4 co-text-blue-500 co-ml4"
                style="cursor: pointer;"
                @click="onItem(item)"
              >查看详情</span>
            </span>
            <span
              v-else
              class="co-text-md mb-2"
            >
              {{ item.title }}
              <span
                class="co-text-xs co-bg-blue-100 co-round co-py2 co-px4 co-text-blue-500 co-ml4"
                style="cursor: pointer;"
                @click="onItem(item)"
              >查看详情</span>
            </span>

            <v-divider />
            <div class="co-flex-row co-items-center mt-2">
              <span class="text-body-2 blue-grey--text lighten-3">
                {{ item.createTime }}
              </span>
              <span
                v-if="item.typeDesc.text"
                class="co-text-2xs co-text-white co-py1 co-px3 co-round co-ml6"
                :class="[backColors[item.typeDesc.color || 'black']]"
              >
                {{ item.typeDesc.text }}
              </span>
            </div>

            <el-image
              v-if="item.cover && item.type !== 'user'"
              :src="item.cover"
              fit="contain"
              style="max-width: 500px; height: auto;"
              class="rounded mt-4 mb-6"
              @click="onItem(item)"
            />
          </div>
          <div
            v-if="items.length < 1"
            class="d-flex flex-row my-8"
          >
            <span class="co-text-md co-text-gray-500">
              当前没有查询结果，请输入关键字进行查询
            </span>
          </div>
          <div
            v-if="pagination.length"
            class="d-flex flex-row my-8"
          >
            <v-pagination
              v-model="pagination.current"
              :length="pagination.length"
              :total-visible="pagination.totalVisible"
              @input="changePagination($event)"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </land-section>
</template>

<script>
  import api from '@/api/co.api'
  import app from '@/api/co.app'
  import lib from '@/api/co.lib'
  import web from '@/api/web/co.web'
  import user from '@/api/co.user'
  import mixPreset from '@/pages/mixins/mix.preset'

  export default {
    data () {
      return {
        currUser: {},
        backColors: app.style.back.colors,
        pgMeta: {},
        extended: false,
        params: null,
        items: [],
        pagination: {
          current: 1,
          length: 0,
          totalVisible: 7
        },
        selResult: {
          count: 0,
          content: ''
        },
        btnFind: {
          content: '',
          text: '查询',
          color: 'primary',
          width: '100'
        },
        selTags: [],
      }
    },
    created () {
      this.currUser = user.info.get()
      this.configTags()
      this.configParams()
    },
    mounted () {
      // console.log('mounted')
      this.getMeta()

      this.btnFind.content = this.pgMeta.content || ''
      this.params.data.currentPage = this.pgMeta.currentPage || '1'
      const type = this.pgMeta.type || ''
      this.setTags(type)

      this.refreshSearch(this.pgMeta)
    },
    destroyed () {
      // console.log('destroyed')
      this.pgMeta = {}
      const value = JSON.stringify(this.pgMeta)
      lib.cookie.set('Search', value)
    },
    methods: {
      refreshSearch (ev = {}) {
        const title = ev.content || ''
        const type = ev.type || ''
        const currentPage = ev.currentPage || '1'

        if (title) {
          this.toFind({ title, type, currentPage })
        }
      },
      setTags (type = '') {
        if (type) {
          const arr = type.split('|')
          for (const i in this.selTags) {
            const tag = this.selTags[i]
            if (arr.includes(tag.value)) {
              tag.selected = true
            } else {
              tag.selected = false
            }
          }
        } else {
          for (const i in this.selTags) {
            const tag = this.selTags[i]
            tag.selected = true
          }
        }
      },
      setMeta () {
        this.pgMeta.content = this.btnFind.content
        this.pgMeta.currentPage = this.params.data.currentPage || '1'
        this.pgMeta.type = this.getType()

        const value = JSON.stringify(this.pgMeta)
        lib.cookie.set('Search', value)
      },
      getMeta () {
        this.pgMeta = api.comm.jsonToObject(lib.cookie.get('Search'))
      },
      toPound (ev) {
        if (!ev.isPound) {
          return
        }
        const params = {
          title: ev.title,
          type: 'content',
          talkTopic: ev.title
        }
        api.page.navigate(this, 'pound', params)
      },
      onItem (item) {
        if (item.type === web.search.Types.USER) {
          const userId = item.id
          if (userId === this.currUser.userId) {
            const params = {
              ...this.currUser
            }
            api.page.navigate(this, 'my', params)
          } else {
            api.page.navigateBy(this, 'homepage', {
              userId
            })
          }
        } else if (item.type === web.search.Types.CONTENT) {
          const params = {
            contentId: item.id,
            title: item.title
          }
          const name = mixPreset.DetailPages.Hot
          lib.cookie.set(name, JSON.stringify(params))
          this.$router.push({ name })
        } else if (item.type === web.search.Types.ACTIVITY) {
          const params = {
            activityId: item.id,
          }
          api.page.navigateBy(this, 'activityDetail', params)
        }
      },
      onTag (tag) {
        tag.selected = !tag.selected
      },
      configTags () {
        this.selTags = []
        for (const key in web.search.TypeDescs) {
          const desc = web.search.TypeDescs[key]
          this.selTags.push({
            ...desc,
            selected: true
          })
        }
      },
      changePagination (number) {
        const params = this.params
        const data = this.params.data
        const index = parseInt(number, 10) || 1

        this.pagination.current = index
        data.currentPage = index
        params.reload = true
        api.httpx.getItems(params)

        this.setMeta()
      },
      configParams () {
        const me = this
        const executed = function (res) {
          // console.log('me.items: %o', me.items)
          me.selResult.count = res.total
          const data = me.params.data
          const pagination = me.pagination
          const pageSize = parseInt(data.pageSize) || 10
          pagination.length = Math.ceil(res.total / pageSize)

          for (const i in me.items) {
            const item = me.items[i]
            // console.log('title: %o', item.title)
            // web.comm.parseTitleEx(item.title)
            const titles = web.comm.parseTitleEx(item.title)
            item.titles = titles
          }
        }

        me.params = web.search.getParams({
          caches: me.items,
          executed
        })
      },
      loadContents (reload = true) {
        this.params.reload = reload
        api.httpx.getItems(this.params)
      },
      getType () {
        const arr = []

        for (const i in this.selTags) {
          const item = this.selTags[i]
          if (item.selected) {
            arr.push(item.value)
          }
        }
        return arr.join('|')
      },
      toFind ({
        title = '',
        type = '',
        currentPage = '1'
      }) {
        this.params.data.title = title
        this.params.data.type = type
        this.params.data.currentPage = currentPage

        this.loadContents(true)
      },
      onFind (ev) {
        const title = api.comm.trim(this.btnFind.content)
        if (!title) {
          this.$alert('请输入查询关键字！', '提示', {
            confirmButtonText: '好的',
            type: 'warning'
          })

          return
        }

        const type = this.getType()

        this.selResult.content = title

        this.toFind({
          title,
          type
        })

        this.setMeta()
      },
    }
  }
</script>
